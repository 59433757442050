import { useQuery } from '@apollo/client';
import { AddIcon } from '@chakra-ui/icons';
import {
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Skeleton,
  SkeletonCircle,
  Spacer,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { theme } from '@flume-finance/ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PiChartLine, PiBank, PiBuildingApartment } from 'react-icons/pi';
import AccountIcon from 'src/assets/icons/icons8-merchant-account.svg?react';
import { EditAssetModal } from 'src/components/overview/EditAssetModal';
import { EmptyListCta } from 'src/components/shared/EmptyListCta';
import { ErrorWithRefetch } from 'src/components/shared/ErrorWithRefetch';
import {
  GET_OVERVIEW_ELEMENTS,
  GET_OVERVIEW_ELEMENTS_PLACEHOLDER,
} from 'src/graphql/GetOverviewElements';
import {
  ExternalPlatform,
  OverviewElementType,
  type GetAccountQuery,
  type GetAssetQuery,
  type GetOverviewElementsQuery,
  SyncMode,
} from 'src/graphql/__generated__/graphql';
import { formatCurrency } from 'src/util';
import { EditAccountModal } from './EditAccountModal';
import { EditItemModal } from './EditItemModal';
import { CreateAccountModal } from './createAccountFlow/CreateAccountModal';
import { ItemStatus } from './shared/ItemStatus';
import { SettingsIconButton } from './shared/SettingsIconButton';
import { EditInvestmentAccountModal } from './EditInvestmentAccountModal';

export type Item = GetOverviewElementsQuery['overviewElements']['items'][0];
type Items = Item[];

interface ItemHeaderProps {
  item: Item;
  isLoaded: boolean;
  onReauthorizeClick: (item: Item) => void;
}

const ItemHeader = ({ item, isLoaded, onReauthorizeClick }: ItemHeaderProps) => {
  return (
    <Flex
      justifyContent={'space-between'}
      alignItems="center"
      bg={'containerBg'}
      minH={8}
      px={4}
      borderRadius="3xl"
    >
      <Skeleton isLoaded={isLoaded}>
        <Text casing={'uppercase'} color="subtleText" fontWeight={'bold'} fontSize="sm">
          {item.institutionName}
        </Text>
      </Skeleton>
      <SkeletonCircle
        isLoaded={isLoaded}
        display="flex"
        justifyContent={'center'}
        alignItems="center"
        w={5}
        h={5}
      >
        <ItemStatus item={item} onReauthorizeClick={onReauthorizeClick} />
      </SkeletonCircle>
    </Flex>
  );
};

interface ItemsTableProps {
  items: Items;
  onAddAccountOpen: () => void;
  onAccountSettingsClick: (accountId: GetAccountQuery['account']['id']) => void;
  onAssetSettingsClick: (assetId: GetAssetQuery['asset']['id']) => void;
  onInvestmentSettingsClick: (investmentId: GetAccountQuery['account']['id']) => void;
  onReauthorizeClick: (item: Item) => void;
  isLoaded: boolean;
  hidden: boolean;
}

const ItemsTable = ({
  items,
  onReauthorizeClick,
  isLoaded,
  hidden,
  onAccountSettingsClick,
  onAssetSettingsClick,
  onInvestmentSettingsClick,
}: ItemsTableProps) => {
  const navigate = useNavigate();

  const getElementIcon = (element: Item['elements'][0]) => {
    switch (element.type) {
      case OverviewElementType.Investment:
        return <Icon as={PiChartLine} fill="iconColor" w={5} />;
      case OverviewElementType.Account:
        return <Icon as={PiBank} fill="iconColor" w={5} />;
      case OverviewElementType.Asset:
        return <Icon as={PiBuildingApartment} fill="iconColor" w={5} />;
    }
  };

  return (
    <Flex flexDirection={'column'} flex={1} w="full" hidden={hidden}>
      {items.map((item) => (
        <Flex
          key={item.id}
          flexDirection={'column'}
          display={item.elements.length === 0 ? 'none' : undefined}
        >
          <ItemHeader item={item} isLoaded={isLoaded} onReauthorizeClick={onReauthorizeClick} />
          {item.elements.map((element, i) => (
            <Flex key={element.id} flexDirection="column">
              <Flex
                alignItems="center"
                justifyContent="space-between"
                px={4}
                py={2}
                onClick={() => {
                  if (element.type === 'ACCOUNT') {
                    navigate(`/transactions?accountId=${element.id}`);
                  }
                }}
                cursor={element.type === 'ACCOUNT' ? 'pointer' : undefined}
                sx={{
                  '@media(hover: hover)': {
                    _hover: {
                      bg: 'hoverBg',
                    },
                  },
                }}
              >
                <Skeleton isLoaded={isLoaded}>
                  <Flex gap={2} alignItems="center" flex={1}>
                    {getElementIcon(element)}
                    <Text>{element.nickname}</Text>
                  </Flex>
                </Skeleton>
                <Flex gap={2} alignItems="center">
                  <Skeleton isLoaded={isLoaded}>
                    <Text>
                      {(item.lastSyncedAt ?? item.lastInvestmentSyncedAt) ||
                      element.syncMode !== SyncMode.Automatic ? (
                        formatCurrency({
                          cents: element.balance,
                          currencyCode: element.currencyCode,
                        })
                      ) : (
                        <Spinner size="sm" color={'iconColor'} speed="1s" emptyColor="hoverBg" />
                      )}
                    </Text>
                  </Skeleton>
                  <SettingsIconButton
                    onClick={() => {
                      switch (element.type) {
                        case OverviewElementType.Account:
                          onAccountSettingsClick(element.id);
                          break;
                        case OverviewElementType.Asset:
                          onAssetSettingsClick(element.id);
                          break;
                        case OverviewElementType.Investment:
                          onInvestmentSettingsClick(element.id);
                          break;
                      }
                    }}
                    isDisabled={!isLoaded}
                  />
                </Flex>
              </Flex>
              <Divider hidden={i === item.elements.length - 1} />
            </Flex>
          ))}
        </Flex>
      ))}
    </Flex>
  );
};

export const AccountSection = () => {
  const { t } = useTranslation();
  const { data, error, refetch } = useQuery<GetOverviewElementsQuery>(GET_OVERVIEW_ELEMENTS, {
    notifyOnNetworkStatusChange: true,
  });
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: isAssetOpen, onClose: onAssetClose, onOpen: onAssetOpen } = useDisclosure();
  const { isOpen: isOpenItem, onClose: onCloseItem, onOpen: onOpenItem } = useDisclosure();
  const {
    isOpen: isAddAccountOpen,
    onClose: onAddAccountClose,
    onOpen: onAddAccountOpen,
  } = useDisclosure();
  const [selectedAccountId, selectAccountId] = useState<
    GetAccountQuery['account']['id'] | undefined
  >(undefined);
  const [selectedAssetId, selectAssetId] = useState<GetAssetQuery['asset']['id'] | undefined>(
    undefined,
  );
  const [selectedItem, selectItem] = useState<Item | undefined>(undefined);
  const {
    isOpen: isInvestmentOpen,
    onClose: onInvestmentClose,
    onOpen: onInvestmentOpen,
  } = useDisclosure();
  const [selectedInvestmentId, selectInvestmentId] = useState<
    GetAccountQuery['account']['id'] | undefined
  >(undefined);

  const isInitialLoaded = data !== undefined;
  const items =
    data === undefined
      ? GET_OVERVIEW_ELEMENTS_PLACEHOLDER.overviewElements.items
      : data.overviewElements.items;

  return (
    <Flex flexGrow={1} flexDir={'column'} gap={theme.space[4]} alignItems="center" pb={4}>
      <Flex alignItems={'center'} justify="space-between" width={'100%'}>
        <Heading size="md">{t('accounts.title')}</Heading>
        <Button
          colorScheme={'gray'}
          variant={'outline'}
          boxShadow="base"
          onClick={onAddAccountOpen}
          leftIcon={<AddIcon />}
          aria-label={t('accounts.actionButtonText') ?? undefined}
          size={'sm'}
          borderRadius={'md'}
        >
          {t('accounts.actionButtonText')}
        </Button>
      </Flex>
      <Flex
        flex={1}
        hidden={items.some((item) => item.elements.length > 0) || !isInitialLoaded || !!error}
        flexDir="column"
      >
        <Spacer flexBasis={'60%'} />
        <EmptyListCta
          title={t('accounts.emptyListCta.title')}
          description={t('accounts.emptyListCta.description')}
          Icon={AccountIcon}
          buttonText={t('accounts.emptyListCta.buttonText')}
          onClick={onAddAccountOpen}
        />
        <Spacer flexBasis={'40%'} />
      </Flex>
      <ItemsTable
        items={items}
        onAddAccountOpen={onAddAccountOpen}
        onAccountSettingsClick={(accountId: GetAccountQuery['account']['id']) => {
          selectAccountId(accountId);
          onOpen();
        }}
        onAssetSettingsClick={(assetId: GetAssetQuery['asset']['id']) => {
          selectAssetId(assetId);
          onAssetOpen();
        }}
        onInvestmentSettingsClick={(investmentId: GetAccountQuery['account']['id']) => {
          selectInvestmentId(investmentId);
          onInvestmentOpen();
        }}
        onReauthorizeClick={(item: Item) => {
          if (item.externalPlatformType === ExternalPlatform.Plaid) {
            selectItem(item);
            onOpenItem();
          }
        }}
        isLoaded={isInitialLoaded}
        hidden={!!error}
      />

      <ErrorWithRefetch message="Failed to load accounts." hidden={!error} refetch={refetch} />
      <EditAccountModal isOpen={isOpen} onClose={onClose} accountId={selectedAccountId} />
      <EditAssetModal isOpen={isAssetOpen} onClose={onAssetClose} assetId={selectedAssetId} />
      {selectedItem && (
        <EditItemModal isOpen={isOpenItem} onClose={onCloseItem} item={selectedItem} />
      )}
      <CreateAccountModal isOpen={isAddAccountOpen} onCloseModal={onAddAccountClose} />
      {selectedInvestmentId && (
        <EditInvestmentAccountModal
          isOpen={isInvestmentOpen}
          onClose={onInvestmentClose}
          accountId={selectedInvestmentId}
        />
      )}
    </Flex>
  );
};
