import { useQuery, useMutation } from '@apollo/client';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  type useDisclosure,
  Button,
  Spinner,
} from '@chakra-ui/react';
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyListCta } from 'src/components/shared/EmptyListCta';
import { ErrorWithRefetch } from 'src/components/shared/ErrorWithRefetch';
import { SharedTable } from 'src/components/shared/SharedTable';
import { GET_ACCOUNT } from 'src/graphql/GetAccount';
import { GET_HOLDINGS, GET_HOLDINGS_PLACEHOLDER } from 'src/graphql/GetHoldings';
import type { GetAccountQuery, GetHoldingsQuery } from 'src/graphql/__generated__/graphql';
import { EditPanel } from './EditAccountModal';
import { PiChartLine } from 'react-icons/pi';
import { formatCurrency } from 'src/util';
import { SYNC_INVESTMENT_HOLDINGS } from 'src/graphql/SyncInvestmentHoldings';
import { cache } from 'src/cache';

interface EditInvestmentAccountModalProps {
  isOpen: boolean;
  onClose: ReturnType<typeof useDisclosure>['onClose'];
  accountId: GetAccountQuery['account']['id'];
}

type ColumnData = GetHoldingsQuery['holdings'][number];

const ValuesPanel = ({
  accountId,
}: {
  accountId: string;
}) => {
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useQuery(GET_HOLDINGS, {
    variables: { accountId: accountId },
  });

  const columnHelper = createColumnHelper<ColumnData>();
  // biome-ignore lint/suspicious/noExplicitAny: Quirk of @tanstack/react-table and typescript
  const columns: ColumnDef<ColumnData, any>[] = useMemo(
    () => [
      columnHelper.accessor((row) => row.security.ticker, {
        id: 'ticker',
        header: 'Ticker',
        enableSorting: false,
        enableColumnFilter: false,
        meta: {
          flexGrow: 1,
          flexBasis: 70,
        },
        cell: (props) => (
          <Flex h={8} alignItems="center">
            <Text fontWeight="bold">{props.getValue()}</Text>
          </Flex>
        ),
      }),
      columnHelper.accessor((row) => row.quantity, {
        id: 'quantity',
        header: '# of Shares',
        enableSorting: false,
        enableColumnFilter: false,
        meta: {
          flexGrow: 1,
          flexBasis: 100,
        },
        cell: (props) => (
          <Flex h={8} alignItems="center">
            <Text>
              {props
                .getValue()
                .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </Text>
          </Flex>
        ),
      }),
      columnHelper.accessor((row) => row.value, {
        id: 'value',
        header: 'Market Value',
        enableSorting: false,
        enableColumnFilter: false,
        meta: {
          flexGrow: 2,
          flexBasis: 150,
        },
        cell: (props) => (
          <Flex h={8} alignItems="center">
            <Text>
              {formatCurrency({
                cents: props.getValue(),
                currencyCode: props.row.original.currencyCode,
              })}
            </Text>
          </Flex>
        ),
      }),
    ],
    [columnHelper],
  );

  if (error) {
    return (
      <ErrorWithRefetch
        message="There was an error loading your investment holdings."
        refetch={refetch}
      />
    );
  }

  const holdings = data?.holdings !== undefined ? data.holdings : GET_HOLDINGS_PLACEHOLDER.holdings;

  return (
    <Flex flex={1} h={'30rem'} flexDirection="column" gap={2} mx={-4}>
      <SharedTable
        columns={columns}
        data={holdings}
        fetchMore={() => {
          /* do nothing */
        }}
        hasMore={false}
        isInitialLoaded={data !== undefined}
        isLoading={loading}
        onRowClick={(_entity: ColumnData) => {
          /* do nothing */
        }}
        showHeading={false}
        showHeadingBreadcrumbs={false}
        resourceNamePlural={t('investments.holdings')}
        error={undefined}
        emptyComponent={
          <EmptyListCta
            title={t('investments.holdings')}
            description={t('investments.noHoldings')}
            Icon={PiChartLine}
          />
        }
      />
    </Flex>
  );
};

const SyncPanel = ({
  itemId,
}: {
  itemId: string;
}) => {
  const { t } = useTranslation();
  const [syncHoldings, { loading }] = useMutation(SYNC_INVESTMENT_HOLDINGS, {
    variables: { itemId },
    onCompleted: () => {
      // Invalidate the holdings query to force a refresh
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'holdings' });
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'overviewElements' });
      cache.gc();
    },
  });

  return (
    <Flex direction="column" gap={4}>
      <Text>{t('investments.syncPanel.description')}</Text>
      <Button
        onClick={() => syncHoldings()}
        isLoading={loading}
        loadingText={t('investments.syncPanel.syncing')}
        alignSelf="flex-end"
      >
        {loading ? <Spinner size="sm" /> : t('investments.syncPanel.syncButton')}
      </Button>
    </Flex>
  );
};

export const EditInvestmentAccountModal = ({
  isOpen,
  onClose,
  accountId,
}: EditInvestmentAccountModalProps) => {
  const { t } = useTranslation();
  const { data } = useQuery(GET_ACCOUNT, {
    variables: { id: accountId as string },
    skip: accountId === undefined,
  });

  const account = data?.account;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Skeleton isLoaded={account !== undefined}>
            <Text>{account?.nickname}</Text>
          </Skeleton>
        </ModalHeader>
        <ModalBody>
          <Tabs variant={'enclosed'}>
            <TabList>
              <Tab>{t('investments.editModal.valuesTabLabel')}</Tab>
              <Tab>{t('investments.editModal.syncTabLabel')}</Tab>
              <Tab>{t('accounts.editModal.tabs.editTabLabel')}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ValuesPanel accountId={accountId} />
              </TabPanel>
              <TabPanel>
                {/* TODO: Disable the account type select for connected accounts */}
                {account?.item?.id ? <SyncPanel itemId={account.item.id} /> : null}
              </TabPanel>
              <TabPanel>
                {account ? <EditPanel account={account} onClose={onClose} /> : null}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};
