export const translation = {
  overview: {
    title: 'Overview',
    netWorth: {
      netWorthLabel: 'Net Worth',
      debtsLabel: 'Debts',
      assetsLabel: 'Assets',
      deltaLabel: 'from last month',
    },
    actionItems: {
      title: 'Action Items',
      transactionCategorizeAlert: {
        body: '{{transactionCount}} transactions need a category',
      },
    },
  },
  accounts: {
    title: 'Accounts',
    actionButtonText: 'Create Account',
    connectionNeedsAttention:
      'The connection with your bank needs attention. Click here to reauthorize the connection.',
    connectionSynced: 'Accounts last synced {{durationAgo}}',
    emptyListCta: {
      title: 'Accounts',
      description:
        'Import transactions from your accounts by connecting your bank accounts or importing from CSV files.',
      buttonText: 'Create an Account',
    },
    editModal: {
      tabs: {
        editTabLabel: 'Edit',
        importTabLabel: 'Import',
        mergeAccountTabLabel: 'Merge',
      },
      mergeAccountTab: {
        description:
          'Merge another account into this account. This will copy all transactions from the other account into this account, merge any duplicate transactions, and delete the other account. This action cannot be undone.',
        placeholder: 'Select an account',
        selectAccountLabel: 'Account to merge into this account',
        mergeButton: 'Merge',
      },
    },
    settingsButtonLabel: 'Settings',
    createAutomaticModal: {
      plaidHelperText: 'We use Plaid to integrate with 2000+ banks',
      or: 'or',
      directIntegrationHelperText: 'We have direct integrations with a handful of partners',
      splitwise: {
        buttonText: 'Splitwise',
        ariaLabel: 'Connect Splitwise',
      },
      coinbase: {
        buttonText: 'Coinbase',
        ariaLabel: 'Connect Coinbase',
      },
      venmo: {
        buttonText: 'Connect Venmo',
        ariaLabel: 'Connect Venmo',
        helperText: 'Import transactions from Venmo by forwarding email receipts',
        balanceHelperText: 'The current balance of your Venmo account',
      },
    },
    select: {
      allAccountsOption: 'All Accounts',
    },
  },
  automations: {
    title: 'Automations',
    actionButtonText: 'Create Automation',
    table: {
      resourceName: 'Automation',
      resourceNamePlural: 'Automations',
      deleteAriaLabel: 'Delete',
    },
    createModal: {
      title: 'Create Automation',
      overrideLabel: 'Override existing categorized transactions',
      buttonText: 'Create',
      triggerValuePlaceholder: 'string (case insensitive)',
      if: 'If',
      thenLabel: 'Then Set',
      to: 'To',
      description:
        'New and existing transactions that meet these criteria will be automatically categorized (if they do not already have a category). You can optionally override the category of categorized transactions using the checkbox',
      affectedTransactionCount: 'This automation will update {{count}} transactions',
    },
    emptyListCta: {
      title: 'Automations',
      description:
        'Automate the categorization of your transactions by creating custom rules. These rules automatically apply to transactions as they are imported and are retroactively applied to uncategorized transactions.',
      buttonText: 'Create an Automation',
    },
  },
  transactions: {
    table: {
      resourceName: 'Transaction',
      resourceNamePlural: 'Transactions',
    },
    emptyListCta: {
      title: 'Transactions',
      description: 'View, search, and categorize your transactions all in one place. ',
      buttonText: 'Import Transactions',
    },
    editModal: {
      tabs: {
        editTabLabel: 'Edit',
        transferTabLabel: 'Transfer',
        splitTabLabel: 'Split on Venmo',
      },
      editTab: {
        merchantLabel: 'Merchant',
        notesLabel: 'Notes',
        reviewedLabel: 'Reviewed',
        categoryLabel: 'Category',
        isReviewedLabel: 'Is Reviewed?',
        isReviewedHelperText: 'Indicate if you have reviewed the transaction',
        hiddenLabel: 'Hidden',
        isHiddenLabel: 'Is Hidden?',
        isHiddenHelperText: 'Hide this transaction from insight charts',
      },
      transferTab: {
        transferTransactionLabel: 'Transfer Transaction',
        transferOptionDefaultValue: 'None',
      },
      splitTab: {
        actionButtonText: '{{type}} {{total}} on Venmo',
        charge: 'Request',
        pay: 'Pay',
        input: {
          venmoUsernameLabel: 'Venmo Username',
          venmoUsernameHelperText: 'Username of the person to request on Venmo',
          addButtonLabel: 'Add Username',
          placeholder: '@kortina',
        },
        table: {
          userHeader: 'User',
          amountHeader: 'Amount to {{type}}',
        },
      },
    },
  },
  budget: {
    emptyListCta: {
      title: 'Budget',
      description:
        'Setup a monthly budget for as many or as few of your categories as you like. Track your spend against each budget throughout the month and adjust as necessary',
      buttonText: 'Setup Categories',
    },
    errorMessageText: 'Failed to load your Budget.',
    modal: {
      budgetLabel: 'Budget',
      invalidAmountErrorText: 'Invalid Budget Amount',
    },
  },
  categories: {
    title: 'Categories',
    createButtonText: 'Category Group',
    defaultCategoryGroupName: 'New Category Group',
    loadErrorMessage: 'Failed to load categories',
    emptyListCta: {
      title: 'Categories',
      description:
        'Setup categories to organize your transactions. These categories can also be used for budgeting to track spending.',
      buttonText: 'Create a Category Group',
    },
    select: {
      allOption: 'All',
      uncategorizedOption: 'Uncategorized',
      transferOption: 'Transfer',
    },
  },
  investments: {
    editModal: {
      valuesTabLabel: 'Holdings',
      syncTabLabel: 'Sync',
    },
    holdings: 'Investment Holdings',
    noHoldings: 'This investment account does not have any holdings tracked in Flume.',
    syncPanel: {
      description:
        'Manually sync investment holdings for this account. This will fetch the latest holdings data from your connected institution.',
      syncButton: 'Sync Holdings',
      syncing: 'Syncing...',
    },
  },
  signUp: {
    title: 'Create Account',
    agreeTosPp: 'I agree to the <0>terms of service</0> and <1>privacy policy</1>',
    nameLabel: 'Name',
    actionButtonText: 'Sign Up',
    linkToLoginText: 'Already have an account? Log in.',
  },
  verifyEmail: {
    title: 'Check your email',
    description:
      "<text>We've sent a confirmation link. Please check your inbox at <bold>{{email}}</bold>.</text>",
    resendButtonText: "Didn't get an email? Send it again",
    wrongEmailLinkText: 'Wrong email? Go back to the sign up page.',
    verifyButtonText: 'Verify Email',
    errorInvalidCodeText: 'The provided code is invalid',
    codeInputPlaceholder: 'abcde12345',
    infoAlertDescription: 'Remember to check your spam folder!',
  },
  navigation: {
    expandButtonLabel: 'Expand Sidebar',
    links: {
      overviewText: 'Overview',
      transactionsText: 'Transactions',
      budgetText: 'Budget',
      insightsText: 'Insights',
      automationsText: 'Automation',
      categoriesText: 'Categories',
      settingsText: 'Settings',
      transactionsReview: 'Review',
    },
    menu: {
      signedInAs: 'Signed in as',
      settingsButtonText: 'Settings',
      discordButtonText: 'Join Discord Server',
      supportButtonText: 'Email Support',
      logOutButtonText: 'Log Out',
    },
  },
  insights: {
    chart: {
      errorMessage: 'Failed to load chart',
      refreshingMessage: 'Refreshing...',
    },
  },
  common: {
    modal: {
      updateButtonText: 'Update',
      cancelButtonText: 'Cancel',
    },
    error: {
      retryButtonText: 'Retry',
    },
    table: {
      sortAscLabel: 'Sorted Ascending',
      sortDescLabel: 'Sorted Descending',
    },
    buttons: {
      editCategoryButtonText: 'Edit Categories',
    },
  },
  billing: {
    title: 'Billing',
    products: {
      title: 'Choose your Subscription',
      errorLoadingMessage: 'Failed to load prices',
      trialButtonText: 'Start Free Trial',
      subscribeButtonText: 'Subscribe',
      trialMessageText:
        'Your free trial will end on {{- date}}. Choose a plan below to continue using Flume.',
      operatedBy: '<text>Flume Finance is operated by <bold>Vineyard Money LLC</bold></text>',
    },
    subscriptions: {
      errorLoadingMessage: 'Failed to load your subscription.',
    },
    tiles: {
      priceInfo: {
        monthly: 'monthly',
        yearly: 'yearly',
        linkText: 'Switch to {{interval}} billing',
        includesTax: 'inc. tax',
        canceledText: 'CANCELED',
      },
      recurrenceInfo: {
        trialTitle: 'Trail ends on',
        regularTitle: 'Next payment due',
        linkText: 'View payment history',
      },
      paymentInfo: {
        title: 'Payment Info',
        updatePaymentLinkText: 'Update payment method',
        cancelSubscriptionLinkText: 'Cancel subscription',
        renewSubscriptionLinkText: 'Renew subscription',
      },
    },
    freeTrialMessage: 'Try Flume free for 14 days',
  },
  notifications: {
    title: 'Web Push Notifications',
    subscribeButtonText: 'Enable Notifications',
    unsubscribeButtonText: 'Disable Notifications',
    errorText: 'Failed to subscribe to notifications',
    supported: {
      prompt: {
        title: 'Know when transactions are imported',
        description:
          'You will be notified on this device whenever a new transaction is imported into Flume. ',
      },
      granted: {
        title: 'Notifications are enabled',
        enabledDescriptionText:
          'You are receiving notifications in this browser each time a new transaction is imported into Flume.',
      },
      denied: {
        title: 'Permission Denied',
        description:
          'Web Push Notification permissions has been denied for this browser. You will need to go into the browser settings if you wish to enable them',
      },
    },
    unsupported: {
      description:
        'This browser does not currently support notifications. The following capabilities must be enabled and supported:',
    },
  },
  categorize: {
    title: 'Categorize',
    transactions: {
      notesInputPlaceholder: 'Notes',
      payeeEditButton: 'Edit Payee',
      emptyListCta: {
        title: 'All categorized!',
        description:
          'All of your outstanding transactions have been categorized (or skipped). Head on over to the insights page to view charts of your spending over time.',
        buttonText: 'View Insights',
      },
    },
    automationSuggestions: {
      emptyListCta: {
        title: 'All automated!',
        description:
          'All of your automation suggestions have been reviewed. Any created automations will immediately categorize existing and future transactions for that merchant. Next you can categorize any remaining transactions',
        buttonText: 'Categorize Transactions',
      },
      transactionCount_one: 'transaction',
      transactionCount_other: 'transactions',
    },
    cardStack: {
      estimateText_one: '< {{count}} minute',
      estimateText_other: '~{{count}} minutes',
      transactions: {
        remainingText_one: '{{count}} transaction',
        remainingText_other: '{{count}} transactions',
      },
      automationSuggestions: {
        remainingText_one: '{{count}} automation suggestion',
        remainingText_other: '{{count}} automation suggestions',
      },
    },
    skipButtonText: 'Skip',
    backButtonText: 'Back',
  },
  settings: {
    account: {
      deleteAccountModal: {
        title: 'Are you sure you want to delete this account?',
        description: 'Please read and understand the following before continuing with deletion:',
        warningText:
          'You cannot undo this action. All of your financial data will be permanently deleted from Flume.',
        checklist: {
          backup: 'I have downloaded a copy of my data using "Export Data"',
          subscription:
            'I understand my subscription will be cancelled and I will lose access to any remaining time in my current subscription period',
          notRecoverable: 'I understand that deleted data is not recoverable',
        },
        emailConfirmation: {
          label: 'Type "{{email}}" to confirm',
          errorText: 'Email does not match',
        },
        action: {
          delete: 'Delete Account',
          cancel: 'Keep Account',
        },
      },
    },
  },
  items: {
    editModal: {
      connectionRemovedSuccessfully: 'Connection with {{institutionName}} removed successfully',
      errorRemovingConnection: 'Error removing connection',
      removeConnection: 'Remove Connection',
      removeConnectionDescription:
        'Remove this connection with your bank. Your transaction history will be preserved and your accounts will be converted to manual accounts.',
    },
  },
};
