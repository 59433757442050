import { useReactiveVar, useQuery } from '@apollo/client';
import { Alert, AlertTitle, AlertIcon, AlertDescription, Flex, Spinner } from '@chakra-ui/react';
import { theme } from '@flume-finance/ui';
import { utc } from 'src/util/dayjs';

import { useEffect, useState } from 'react';
import { cache, type PlaidItemConnection, plaidItemConnection } from 'src/cache';
import { GET_ITEM, type GetItemInput, type GetItemData } from 'src/graphql/GetItem';

type AlertInfo = {
  title: string;
  description: string;
  status: 'info' | 'success' | 'warning';
};

const getAlertInfo = (connection: PlaidItemConnection): AlertInfo => {
  switch (connection.status) {
    case 'success':
      return {
        title: 'Import Complete',
        description:
          'The last month of transactions have been imported. Additional transactions will continue importing behind the scenes.',
        status: 'success',
      };
    case 'importing':
      return {
        title: 'Importing Transactions...',
        description: `A connection with ${connection.institutionName} has been established and your transaction data is being prepared. This usually takes about 30 seconds, but can take up to few minutes.`,
        status: 'info',
      };
    case 'timeout':
      return {
        title: 'Import is taking longer than usual',
        description:
          'Your transaction data will continue to be prepared behind the scenes. Check back later',
        status: 'warning',
      };
  }
};

function PlaidImportIndicator() {
  const plaidItemConnectionValue = useReactiveVar(plaidItemConnection);
  const [pollingTimeout, setPollingTimeout] = useState<NodeJS.Timeout | undefined>(undefined);
  // TODO: Should match whatever value we use in the webhook service for default update. e.g. if we pull at most once an hour this should be dayjs.utc() - 1.hour
  const [startDatetime] = useState(utc());
  const { data, stopPolling } = useQuery<GetItemData, GetItemInput>(GET_ITEM, {
    variables: { itemId: plaidItemConnectionValue?.itemId ?? 'invalid_uuid' },
    pollInterval: 2500,
    skip: plaidItemConnectionValue === undefined,
  });

  // Stop polling after 30 seconds and display a warning message that things are taking longer than usual
  useEffect(() => {
    if (plaidItemConnectionValue?.status === 'importing') {
      setPollingTimeout(
        setTimeout(() => {
          plaidItemConnection({
            ...plaidItemConnectionValue,
            status: 'timeout',
          });
          stopPolling();
        }, 30_000),
      );
    }
  }, [plaidItemConnectionValue, stopPolling]);

  // Fetch transactions and stop polling once the item has been synced by the backend
  useEffect(() => {
    const isRecentlySynced =
      (data?.item.lastSyncedAt ?? data?.item.lastInvestmentSyncedAt) &&
      utc(data?.item.lastSyncedAt ?? data?.item.lastInvestmentSyncedAt).diff(startDatetime) > 0;
    if (isRecentlySynced && plaidItemConnectionValue) {
      // Evict all transaction related queries since it is complicated to manually adjust the cache
      // Only run if the status isn't already 'success' to avoid an infinite re-render loop
      if (plaidItemConnectionValue.status === 'importing') {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'transactions' });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'overviewElements' });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'netWorthHistory' });
        cache.gc();

        pollingTimeout && clearTimeout(pollingTimeout);
        stopPolling();
        plaidItemConnection({ ...plaidItemConnectionValue, status: 'success' });
      }
    }
  }, [
    stopPolling,
    data?.item.lastSyncedAt,
    data?.item.lastInvestmentSyncedAt,
    plaidItemConnectionValue,
    startDatetime,
    pollingTimeout,
  ]);

  // Hide alert after 7 second when the connection enters a terminal status (success or timeout)
  useEffect(() => {
    if (
      plaidItemConnectionValue?.status === 'success' ||
      plaidItemConnectionValue?.status === 'timeout'
    ) {
      // Hide the alert after a few seconds
      setTimeout(() => {
        plaidItemConnection(undefined);
      }, 7_000);
    }
  }, [plaidItemConnectionValue?.status]);

  if (plaidItemConnectionValue === undefined) {
    return null;
  }

  const alertInfo = getAlertInfo(plaidItemConnectionValue);

  return (
    <Alert
      width={{ base: 'auto', md: 400 }}
      zIndex={theme.zIndices.toast}
      position="fixed"
      bottom={2}
      right={{ base: 2, md: 0 }}
      left={{ base: 2, md: 0 }}
      marginX="auto"
      boxShadow={{ base: 'xl', xl: 'md' }}
      padding={5}
      borderRadius={8}
      status={alertInfo.status}
      variant="solid"
      flexDirection="column"
      alignItems="flex-start"
    >
      <Flex mb={2} alignItems="center">
        {alertInfo.status === 'info' ? (
          <Spinner size="sm" colorScheme={'blue'} mr={3} />
        ) : (
          <AlertIcon />
        )}
        <AlertTitle as="h2">{alertInfo.title}</AlertTitle>
      </Flex>

      <AlertDescription maxWidth="sm">{alertInfo.description}</AlertDescription>
    </Alert>
  );
}

export { PlaidImportIndicator };
