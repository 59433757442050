/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n    mutation CreateAsset($name: String!, $type: AssetType!, $currentBalance: Bigint!, $currencyCode: String!) {\n      createAsset(name: $name, type: $type, currentBalance: $currentBalance, currencyCode: $currencyCode) {\n        id\n      }\n    }\n  ": types.CreateAssetDocument,
    "\n    mutation CreateAssetValue($assetId: ID!, $date: DateTime!, $balance: Bigint!) {\n      createAssetValue(assetId: $assetId, date: $date, balance: $balance) {\n        id\n        date\n        value\n      }\n    }\n  ": types.CreateAssetValueDocument,
    "\n    mutation CreateAutomation($createAutomationInput: CreateAutomationInput!) {\n      createAutomation(createAutomationInput: $createAutomationInput) {\n        ...AutomationFields\n      }\n    }\n    \n  ": types.CreateAutomationDocument,
    "\n  mutation CreateCategory($name: String!, $color: String!, $groupId: String!) {\n    createCategory(name: $name, color: $color, groupId: $groupId) {\n      id\n      color\n      name\n    }\n  }\n": types.CreateCategoryDocument,
    "\n  mutation CreateCategoryGroup($name: String!) {\n    createCategoryGroup(name: $name) {\n      ...CategoryGroupFields\n    }\n  }\n  \n": types.CreateCategoryGroupDocument,
    "\n  mutation CreateEmailAccount($createAccountArgs: CreateEmailAccountArgs!) {\n    createEmailAccount(createAccountArgs: $createAccountArgs) {\n      id\n      nickname\n      type\n    }\n  }\n": types.CreateEmailAccountDocument,
    "\n  mutation CreateFeedback($description: String!) {\n    createFeedback(description: $description) {\n      id\n    }\n  }\n": types.CreateFeedbackDocument,
    "\n  mutation CreateManualAccount($createAccountArgs: CreateManualAccountArgs!) {\n    createManualAccount(createAccountArgs: $createAccountArgs) {\n      id\n      nickname\n      type\n    }\n  }\n": types.CreateManualAccountDocument,
    "\n  mutation CreateManualTransfer($inflowTransactionId: String!, $outflowTransactionId: String!) {\n    createManualTransfer(\n      inflowTransactionId: $inflowTransactionId\n      outflowTransactionId: $outflowTransactionId\n    ) {\n      id\n      inflowTransaction {\n        id\n      }\n      outflowTransaction {\n        id\n      }\n    }\n  }\n": types.CreateManualTransferDocument,
    "\n  mutation CreatePlaidLinkToken($itemId: String, $product: PlaidLinkProduct!) {\n    createPlaidLinkToken(itemId: $itemId, product: $product) {\n      linkToken\n    }\n  }\n": types.CreatePlaidLinkTokenDocument,
    "\n  mutation CreateTransactionsBatch($transactions: [CreateManualTransactionInput!]!, $accountId: String!) {\n    createTransactions(transactions: $transactions, accountId: $accountId) {\n      success\n    }\n  }\n": types.CreateTransactionsBatchDocument,
    "\n  mutation CreateUser($name: String!, $email: String!, $password: String!) {\n    createUser(name: $name, email: $email, password: $password) {\n      id\n      name\n      email\n    }\n  }\n": types.CreateUserDocument,
    "\n    mutation DeleteAssetValue($assetValueId: ID!) {\n      deleteAssetValue(assetValueId: $assetValueId) {\n        id\n      }\n    }\n  ": types.DeleteAssetValueDocument,
    "\n    mutation DeleteAutomation($automationId: String!) {\n      deleteAutomation(automationId: $automationId) {\n        id\n      }\n    }\n  ": types.DeleteAutomationDocument,
    "\n  mutation DeleteCategory($categoryId: String!) {\n    deleteCategory(categoryId: $categoryId) {\n      id\n    }\n  }\n": types.DeleteCategoryDocument,
    "\n  mutation DeleteCategoryGroup($categoryGroupId: String!) {\n    deleteCategoryGroup(categoryGroupId: $categoryGroupId) {\n      id\n    }\n  }\n": types.DeleteCategoryGroupDocument,
    "\n  mutation DeletePlaidItem($itemId: String!) {\n    deletePlaidItem(itemId: $itemId) {\n      success\n    }\n  }\n": types.DeletePlaidItemDocument,
    "\n  mutation DeleteTransfer($transferId: String!) {\n    deleteTransfer(transferId: $transferId) {\n      id\n    }\n  }\n": types.DeleteTransferDocument,
    "\n  mutation DeleteUser {\n    deleteUser {\n      id\n    }\n  }\n": types.DeleteUserDocument,
    "\n  mutation EditAccount($editAccountArgs: EditAccountArgs!) {\n    editAccount(editAccountArgs: $editAccountArgs) {\n      id\n      nickname\n      type\n      accountNumber\n    }\n  }\n": types.EditAccountDocument,
    "\n  mutation EditCategory($id: String!, $name: String!, $color: String!) {\n    editCategory(id: $id, name: $name, color: $color) {\n      id\n      name\n      color\n    }\n  }\n": types.EditCategoryDocument,
    "\n  mutation EditCategoryGroup($id: String!, $name: String!) {\n    editCategoryGroup(id: $id, name: $name) {\n      id\n      name\n    }\n  }\n": types.EditCategoryGroupDocument,
    "\n  mutation ExchangePublicToken($publicToken: String!) {\n    exchangePublicToken(publicToken: $publicToken) {\n      itemId\n    }\n  }\n": types.ExchangePublicTokenDocument,
    "\nmutation FindOrCreateAccountsByName($createAccountInputs: [CreateManyManualAccountArgs!]!) {\n  findOrCreateAccounts(createAccountInputs: $createAccountInputs) {\n    id\n    nickname\n    type\n  }\n}\n": types.FindOrCreateAccountsByNameDocument,
    "\nmutation FindOrCreateCategoriesByName($categoryGroupName: String!, $categoryNames: [String!]!) {\n  findOrCreateCategoriesByName(categoryGroupName: $categoryGroupName, categoryNames: $categoryNames) {\n    id\n    name\n  }\n}\n": types.FindOrCreateCategoriesByNameDocument,
    "\n  query GetAccount($id: ID!) {\n    account(id: $id) {\n      id\n      nickname\n      type\n      balance\n      accountNumber\n      syncMode\n      item {\n        id\n        externalPlatformType\n        lastSyncedAt\n        expiresAt\n        institutionName\n      }\n    }\n  }\n": types.GetAccountDocument,
    "\n  query GetAccounts {\n    accounts {\n      id\n      nickname\n      type\n      balance\n      accountNumber\n      syncMode\n      item {\n        id\n        externalPlatformType\n        lastSyncedAt\n        expiresAt\n        institutionName\n      }\n    }\n  }\n": types.GetAccountsDocument,
    "\n  query GetCurrentSubscription {\n    activeSubscription {\n      id\n      status\n      currentPeriodEnd\n      cancelAt\n      price {\n        id\n        amount\n        currency\n        recurrenceInterval\n      }\n    }\n  }\n": types.GetCurrentSubscriptionDocument,
    "\n  query GetAsset($id: ID!) {\n    asset(id: $id) {\n      id\n      name\n      type\n      balance\n      currencyCode\n      assetValues {\n        id\n        date\n        value\n      }\n    }\n  }\n": types.GetAssetDocument,
    "\n  query GetPendingAutomationSuggestions {\n    pendingAutomationSuggestions {\n      id\n      field\n      value\n      status\n      automation {\n        id\n      }\n    }\n  }\n": types.GetPendingAutomationSuggestionsDocument,
    "\n  fragment AutomationFields on Automation {\n    id\n    triggers {\n      id\n      field\n      matcher\n      value\n    }\n    actions {\n      id\n      field\n      value\n    }\n  }\n": types.AutomationFieldsFragmentDoc,
    "\n  query GetAutomations {\n    automations {\n      ...AutomationFields\n    }\n  }\n  \n": types.GetAutomationsDocument,
    "\n  fragment CategoryBudgets on Budget {\n    categoryBudgets {\n      id\n      budgeted\n      category {\n        id\n      }\n    }\n  }\n": types.CategoryBudgetsFragmentDoc,
    "\n  query Budget($month: DateTime!) {\n    budget(month: $month) {\n      id\n      month\n      ...CategoryBudgets\n    }\n  }\n  \n": types.BudgetDocument,
    "\n  query GetCategoryAggregation(\n    $startDate: DateTime!\n    $endDate: DateTime!\n    $interval: AggregationInterval!\n  ) {\n    categoryAggregation(startDate: $startDate, endDate: $endDate, interval: $interval) {\n      xLabels\n      startDate\n      endDate\n      aggregations {\n        category {\n          id\n          name\n          color\n        }\n        amounts\n      }\n    }\n  }\n": types.GetCategoryAggregationDocument,
    "\n  fragment CategoryGroupFields on CategoryGroup {\n    id\n    name\n    categories {\n      id\n      name\n      color\n    }\n  }\n": types.CategoryGroupFieldsFragmentDoc,
    "\n  query CategoryGroups {\n    categoryGroups {\n      ...CategoryGroupFields\n    }\n  }\n  \n": types.CategoryGroupsDocument,
    "\n  query CategoryGroupsWithSpend($startDate: DateTime!, $endDate: DateTime!) {\n    categoryGroups {\n      id\n      name\n      categories {\n        id\n        name\n        spend(startDate: $startDate, endDate: $endDate)\n        color\n      }\n    }\n  }\n": types.CategoryGroupsWithSpendDocument,
    "\n  query GetCurrentUser {\n    currentUser {\n      id\n      name\n      email\n      isEmailVerified\n      currencyCode\n      stripeCustomer {\n        subscriptions {\n          id\n          status\n        }\n      }\n    }\n  }\n": types.GetCurrentUserDocument,
    "\n  query GetHoldings($accountId: String!) {\n    holdings(accountId: $accountId) {\n      id\n      security {\n        id\n        ticker\n        name\n      }\n      quantity\n      value\n      currencyCode\n    }\n  }\n": types.GetHoldingsDocument,
    "\n  query GetIncomeVsExpense(\n    $startDate: DateTime!\n    $endDate: DateTime!\n    $interval: AggregationInterval!\n  ) {\n    incomeVsExpense(startDate: $startDate, endDate: $endDate, interval: $interval) {\n      xLabels\n      startDate\n      endDate\n      aggregations {\n        category {\n          id\n          name\n          color\n        }\n        amounts\n      }\n    }\n  }\n": types.GetIncomeVsExpenseDocument,
    "\n  query GetItem($itemId: String!) {\n    item(itemId: $itemId) {\n      id\n      lastSyncedAt\n      lastInvestmentSyncedAt\n    }\n  }\n": types.GetItemDocument,
    "\n  query GetNetWorthHistory(\n    $startDate: DateTime!\n    $endDate: DateTime!\n    $interval: AssetAggregationInterval!\n  ) {\n    netWorthHistory(startDate: $startDate, endDate: $endDate, interval: $interval) {\n      netWorth\n      percentChange\n      history {\n        date\n        value\n      }\n      xLabels\n      xLabelFormat\n    }\n  }\n": types.GetNetWorthHistoryDocument,
    "\n  query GetOverviewElements {\n    overviewElements {\n      items {\n        id\n        lastSyncedAt\n        lastInvestmentSyncedAt\n        expiresAt\n        institutionName\n        externalPlatformType\n        elements {\n          id\n          nickname\n          type\n          balance\n          currencyCode\n          syncMode\n        }\n      }\n    }\n  }\n": types.GetOverviewElementsDocument,
    "\n  query GetPotentialTransfers($transactionId: String!) {\n    potentialTransfers(transactionId: $transactionId) {\n      id\n      date\n      payee\n      account {\n        nickname\n      }\n    }\n  }\n": types.GetPotentialTransfersDocument,
    "\n  query GetRecurringTransactions {\n    recurringTransactions {\n      id\n      amount\n      recurrenceInterval\n      active\n      payee\n      transactions {\n        id\n        date\n      }\n    }\n  }\n": types.GetRecurringTransactionsDocument,
    "\n  query GetStripeProduct {\n    stripeProducts {\n      id\n      name\n      description\n      stripePrices {\n        id\n        amount\n        currency\n        recurrenceInterval\n      }\n    }\n  }\n": types.GetStripeProductDocument,
    "\n  query GetTransaction($transactionId: String!) {\n    transaction(transactionId: $transactionId) {\n      id\n      payee\n      date\n      amount\n      currencyCode\n      account {\n        id\n        nickname\n      }\n      linkedTransfer {\n        id\n        inflowTransaction {\n          id\n        }\n        outflowTransaction {\n          id\n        }\n      }\n    }\n  }\n": types.GetTransactionDocument,
    "\n  query GetTransactions(\n    $accountId: String\n    $cursor: String\n    $sort: TransactionSortOption\n    $filters: FilterOptions!\n  ) {\n    transactions(accountId: $accountId, cursor: $cursor, sort: $sort, filters: $filters) {\n      nextCursor\n      total\n      transactions {\n        id\n        payee\n        rawPayee\n        notes\n        date\n        amount\n        currencyCode\n        reviewed\n        hidden\n        settled\n        category {\n          id\n          name\n          color\n        }\n        merchant {\n          name\n          category\n        }\n        account {\n          id\n          nickname\n        }\n        linkedTransfer {\n          id\n          inflowTransaction {\n            id\n          }\n          outflowTransaction {\n            id\n          }\n        }\n      }\n    }\n  }\n": types.GetTransactionsDocument,
    "\n  mutation ImportStatements($accountId: String!, $statements: [StatementFile!]!) {\n    importStatements(accountId: $accountId, statements: $statements) {\n      filename\n      status\n    }\n  }\n": types.ImportStatementsDocument,
    "\n  mutation Login($email: String!, $password: String!) {\n    login(email: $email, password: $password) {\n      id\n      name\n      email\n      isEmailVerified\n    }\n  }\n": types.LoginDocument,
    "\n  mutation Logout {\n    logout {\n      success\n    }\n  }\n": types.LogoutDocument,
    "\nmutation MergeAccounts($sourceAccountId: String!, $targetAccountId: String!) {\n  mergeAccounts(sourceAccountId: $sourceAccountId, targetAccountId: $targetAccountId) {\n    success\n  }\n}\n": types.MergeAccountsDocument,
    "\n  query GetNetWorthOverview {\n    netWorthOverview {\n      netWorth {\n        current\n        lastMonth\n      }\n      debts {\n        current\n        lastMonth\n      }\n      assets {\n        current\n        lastMonth\n      }\n    }\n  }\n": types.GetNetWorthOverviewDocument,
    "\n      mutation RegisterSubscription($endpoint: String!, $p256dh: String!, $auth: String!) {\n        registerNotificationSubscription(endpoint: $endpoint, p256dh: $p256dh, auth: $auth) {\n          success\n        }\n      }\n    ": types.RegisterSubscriptionDocument,
    "\n    mutation ResetItemExpiresAt($itemId: String!) {\n      resetItemExpiresAt(itemId: $itemId) {\n        id\n        expiresAt\n      }\n    }\n  ": types.ResetItemExpiresAtDocument,
    "\n    mutation SetBulkReviewed($bulkReviewInput: BulkReviewInput!) {\n      setBulkReviewed(bulkReviewInput: $bulkReviewInput) {\n        affectedTransactionIds\n      }\n    }\n  ": types.SetBulkReviewedDocument,
    "\n  mutation SetCategory($transactionId: String!, $categoryId: String) {\n    setCategory(transactionId: $transactionId, categoryId: $categoryId) {\n      id\n      category {\n        id\n      }\n    }\n  }\n": types.SetCategoryDocument,
    "\n  mutation SetCategoryBudget($amount: Float!, $categoryId: String!, $budgetId: String!) {\n    setCategoryBudget(amount: $amount, categoryId: $categoryId, budgetId: $budgetId) {\n      id\n      category {\n        id\n      }\n      budget {\n        id\n      }\n      budgeted\n    }\n  }\n": types.SetCategoryBudgetDocument,
    "\n  mutation SetTransactionHidden($transactionId: String!, $hidden: Boolean!) {\n    setTransactionHidden(transactionId: $transactionId, hidden: $hidden) {\n      id\n      hidden\n    }\n  }\n": types.SetTransactionHiddenDocument,
    "\n  mutation SetTransactionNotes($transactionId: String!, $notes: String!) {\n    setTransactionNotes(transactionId: $transactionId, notes: $notes) {\n      id\n      notes\n    }\n  }\n": types.SetTransactionNotesDocument,
    "\n    mutation SetTransactionPayee($transactionId: String!, $payee: String!) {\n      setTransactionPayee(transactionId: $transactionId, payee: $payee) {\n        affectedTransactionIds\n      }\n    }\n  ": types.SetTransactionPayeeDocument,
    "\n          fragment UpdatePayee on Transaction {\n            id\n            payee\n          }\n        ": types.UpdatePayeeFragmentDoc,
    "\n  mutation SetTransactionReviewed($transactionId: String!, $reviewed: Boolean!) {\n    setTransactionReviewed(transactionId: $transactionId, reviewed: $reviewed) {\n      id\n      reviewed\n    }\n  }\n": types.SetTransactionReviewedDocument,
    "\n  query SimulateAutomation($simulateAutomationInput: SimulateAutomationInput!) {\n    simulateAutomation(simulateAutomationInput: $simulateAutomationInput) {\n      count\n    }\n  }\n": types.SimulateAutomationDocument,
    "\n  mutation SyncInvestmentHoldings($itemId: String!) {\n    syncInvestmentHoldings(itemId: $itemId) {\n      success\n    }\n  }\n": types.SyncInvestmentHoldingsDocument,
    "\n    mutation UpdateAutomationSuggestion(\n      $updateAutomationSuggestionInput: UpdateAutomationSuggestionInput!\n    ) {\n      updateAutomationSuggestion(\n        updateAutomationSuggestionInput: $updateAutomationSuggestionInput\n      ) {\n        id\n        field\n        value\n        status\n        automation {\n          id\n        }\n      }\n    }\n  ": types.UpdateAutomationSuggestionDocument,
    "\n  mutation VerifyEmail($code: String!) {\n    verifyEmail(code: $code) {\n      success\n    }\n  }\n": types.VerifyEmailDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateAsset($name: String!, $type: AssetType!, $currentBalance: Bigint!, $currencyCode: String!) {\n      createAsset(name: $name, type: $type, currentBalance: $currentBalance, currencyCode: $currencyCode) {\n        id\n      }\n    }\n  "): (typeof documents)["\n    mutation CreateAsset($name: String!, $type: AssetType!, $currentBalance: Bigint!, $currencyCode: String!) {\n      createAsset(name: $name, type: $type, currentBalance: $currentBalance, currencyCode: $currencyCode) {\n        id\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateAssetValue($assetId: ID!, $date: DateTime!, $balance: Bigint!) {\n      createAssetValue(assetId: $assetId, date: $date, balance: $balance) {\n        id\n        date\n        value\n      }\n    }\n  "): (typeof documents)["\n    mutation CreateAssetValue($assetId: ID!, $date: DateTime!, $balance: Bigint!) {\n      createAssetValue(assetId: $assetId, date: $date, balance: $balance) {\n        id\n        date\n        value\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateAutomation($createAutomationInput: CreateAutomationInput!) {\n      createAutomation(createAutomationInput: $createAutomationInput) {\n        ...AutomationFields\n      }\n    }\n    \n  "): (typeof documents)["\n    mutation CreateAutomation($createAutomationInput: CreateAutomationInput!) {\n      createAutomation(createAutomationInput: $createAutomationInput) {\n        ...AutomationFields\n      }\n    }\n    \n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateCategory($name: String!, $color: String!, $groupId: String!) {\n    createCategory(name: $name, color: $color, groupId: $groupId) {\n      id\n      color\n      name\n    }\n  }\n"): (typeof documents)["\n  mutation CreateCategory($name: String!, $color: String!, $groupId: String!) {\n    createCategory(name: $name, color: $color, groupId: $groupId) {\n      id\n      color\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateCategoryGroup($name: String!) {\n    createCategoryGroup(name: $name) {\n      ...CategoryGroupFields\n    }\n  }\n  \n"): (typeof documents)["\n  mutation CreateCategoryGroup($name: String!) {\n    createCategoryGroup(name: $name) {\n      ...CategoryGroupFields\n    }\n  }\n  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateEmailAccount($createAccountArgs: CreateEmailAccountArgs!) {\n    createEmailAccount(createAccountArgs: $createAccountArgs) {\n      id\n      nickname\n      type\n    }\n  }\n"): (typeof documents)["\n  mutation CreateEmailAccount($createAccountArgs: CreateEmailAccountArgs!) {\n    createEmailAccount(createAccountArgs: $createAccountArgs) {\n      id\n      nickname\n      type\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateFeedback($description: String!) {\n    createFeedback(description: $description) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateFeedback($description: String!) {\n    createFeedback(description: $description) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateManualAccount($createAccountArgs: CreateManualAccountArgs!) {\n    createManualAccount(createAccountArgs: $createAccountArgs) {\n      id\n      nickname\n      type\n    }\n  }\n"): (typeof documents)["\n  mutation CreateManualAccount($createAccountArgs: CreateManualAccountArgs!) {\n    createManualAccount(createAccountArgs: $createAccountArgs) {\n      id\n      nickname\n      type\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateManualTransfer($inflowTransactionId: String!, $outflowTransactionId: String!) {\n    createManualTransfer(\n      inflowTransactionId: $inflowTransactionId\n      outflowTransactionId: $outflowTransactionId\n    ) {\n      id\n      inflowTransaction {\n        id\n      }\n      outflowTransaction {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateManualTransfer($inflowTransactionId: String!, $outflowTransactionId: String!) {\n    createManualTransfer(\n      inflowTransactionId: $inflowTransactionId\n      outflowTransactionId: $outflowTransactionId\n    ) {\n      id\n      inflowTransaction {\n        id\n      }\n      outflowTransaction {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreatePlaidLinkToken($itemId: String, $product: PlaidLinkProduct!) {\n    createPlaidLinkToken(itemId: $itemId, product: $product) {\n      linkToken\n    }\n  }\n"): (typeof documents)["\n  mutation CreatePlaidLinkToken($itemId: String, $product: PlaidLinkProduct!) {\n    createPlaidLinkToken(itemId: $itemId, product: $product) {\n      linkToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateTransactionsBatch($transactions: [CreateManualTransactionInput!]!, $accountId: String!) {\n    createTransactions(transactions: $transactions, accountId: $accountId) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation CreateTransactionsBatch($transactions: [CreateManualTransactionInput!]!, $accountId: String!) {\n    createTransactions(transactions: $transactions, accountId: $accountId) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateUser($name: String!, $email: String!, $password: String!) {\n    createUser(name: $name, email: $email, password: $password) {\n      id\n      name\n      email\n    }\n  }\n"): (typeof documents)["\n  mutation CreateUser($name: String!, $email: String!, $password: String!) {\n    createUser(name: $name, email: $email, password: $password) {\n      id\n      name\n      email\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation DeleteAssetValue($assetValueId: ID!) {\n      deleteAssetValue(assetValueId: $assetValueId) {\n        id\n      }\n    }\n  "): (typeof documents)["\n    mutation DeleteAssetValue($assetValueId: ID!) {\n      deleteAssetValue(assetValueId: $assetValueId) {\n        id\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation DeleteAutomation($automationId: String!) {\n      deleteAutomation(automationId: $automationId) {\n        id\n      }\n    }\n  "): (typeof documents)["\n    mutation DeleteAutomation($automationId: String!) {\n      deleteAutomation(automationId: $automationId) {\n        id\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteCategory($categoryId: String!) {\n    deleteCategory(categoryId: $categoryId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteCategory($categoryId: String!) {\n    deleteCategory(categoryId: $categoryId) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteCategoryGroup($categoryGroupId: String!) {\n    deleteCategoryGroup(categoryGroupId: $categoryGroupId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteCategoryGroup($categoryGroupId: String!) {\n    deleteCategoryGroup(categoryGroupId: $categoryGroupId) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeletePlaidItem($itemId: String!) {\n    deletePlaidItem(itemId: $itemId) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation DeletePlaidItem($itemId: String!) {\n    deletePlaidItem(itemId: $itemId) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteTransfer($transferId: String!) {\n    deleteTransfer(transferId: $transferId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteTransfer($transferId: String!) {\n    deleteTransfer(transferId: $transferId) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteUser {\n    deleteUser {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteUser {\n    deleteUser {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation EditAccount($editAccountArgs: EditAccountArgs!) {\n    editAccount(editAccountArgs: $editAccountArgs) {\n      id\n      nickname\n      type\n      accountNumber\n    }\n  }\n"): (typeof documents)["\n  mutation EditAccount($editAccountArgs: EditAccountArgs!) {\n    editAccount(editAccountArgs: $editAccountArgs) {\n      id\n      nickname\n      type\n      accountNumber\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation EditCategory($id: String!, $name: String!, $color: String!) {\n    editCategory(id: $id, name: $name, color: $color) {\n      id\n      name\n      color\n    }\n  }\n"): (typeof documents)["\n  mutation EditCategory($id: String!, $name: String!, $color: String!) {\n    editCategory(id: $id, name: $name, color: $color) {\n      id\n      name\n      color\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation EditCategoryGroup($id: String!, $name: String!) {\n    editCategoryGroup(id: $id, name: $name) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  mutation EditCategoryGroup($id: String!, $name: String!) {\n    editCategoryGroup(id: $id, name: $name) {\n      id\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ExchangePublicToken($publicToken: String!) {\n    exchangePublicToken(publicToken: $publicToken) {\n      itemId\n    }\n  }\n"): (typeof documents)["\n  mutation ExchangePublicToken($publicToken: String!) {\n    exchangePublicToken(publicToken: $publicToken) {\n      itemId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation FindOrCreateAccountsByName($createAccountInputs: [CreateManyManualAccountArgs!]!) {\n  findOrCreateAccounts(createAccountInputs: $createAccountInputs) {\n    id\n    nickname\n    type\n  }\n}\n"): (typeof documents)["\nmutation FindOrCreateAccountsByName($createAccountInputs: [CreateManyManualAccountArgs!]!) {\n  findOrCreateAccounts(createAccountInputs: $createAccountInputs) {\n    id\n    nickname\n    type\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation FindOrCreateCategoriesByName($categoryGroupName: String!, $categoryNames: [String!]!) {\n  findOrCreateCategoriesByName(categoryGroupName: $categoryGroupName, categoryNames: $categoryNames) {\n    id\n    name\n  }\n}\n"): (typeof documents)["\nmutation FindOrCreateCategoriesByName($categoryGroupName: String!, $categoryNames: [String!]!) {\n  findOrCreateCategoriesByName(categoryGroupName: $categoryGroupName, categoryNames: $categoryNames) {\n    id\n    name\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAccount($id: ID!) {\n    account(id: $id) {\n      id\n      nickname\n      type\n      balance\n      accountNumber\n      syncMode\n      item {\n        id\n        externalPlatformType\n        lastSyncedAt\n        expiresAt\n        institutionName\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetAccount($id: ID!) {\n    account(id: $id) {\n      id\n      nickname\n      type\n      balance\n      accountNumber\n      syncMode\n      item {\n        id\n        externalPlatformType\n        lastSyncedAt\n        expiresAt\n        institutionName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAccounts {\n    accounts {\n      id\n      nickname\n      type\n      balance\n      accountNumber\n      syncMode\n      item {\n        id\n        externalPlatformType\n        lastSyncedAt\n        expiresAt\n        institutionName\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetAccounts {\n    accounts {\n      id\n      nickname\n      type\n      balance\n      accountNumber\n      syncMode\n      item {\n        id\n        externalPlatformType\n        lastSyncedAt\n        expiresAt\n        institutionName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCurrentSubscription {\n    activeSubscription {\n      id\n      status\n      currentPeriodEnd\n      cancelAt\n      price {\n        id\n        amount\n        currency\n        recurrenceInterval\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCurrentSubscription {\n    activeSubscription {\n      id\n      status\n      currentPeriodEnd\n      cancelAt\n      price {\n        id\n        amount\n        currency\n        recurrenceInterval\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAsset($id: ID!) {\n    asset(id: $id) {\n      id\n      name\n      type\n      balance\n      currencyCode\n      assetValues {\n        id\n        date\n        value\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetAsset($id: ID!) {\n    asset(id: $id) {\n      id\n      name\n      type\n      balance\n      currencyCode\n      assetValues {\n        id\n        date\n        value\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetPendingAutomationSuggestions {\n    pendingAutomationSuggestions {\n      id\n      field\n      value\n      status\n      automation {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPendingAutomationSuggestions {\n    pendingAutomationSuggestions {\n      id\n      field\n      value\n      status\n      automation {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AutomationFields on Automation {\n    id\n    triggers {\n      id\n      field\n      matcher\n      value\n    }\n    actions {\n      id\n      field\n      value\n    }\n  }\n"): (typeof documents)["\n  fragment AutomationFields on Automation {\n    id\n    triggers {\n      id\n      field\n      matcher\n      value\n    }\n    actions {\n      id\n      field\n      value\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetAutomations {\n    automations {\n      ...AutomationFields\n    }\n  }\n  \n"): (typeof documents)["\n  query GetAutomations {\n    automations {\n      ...AutomationFields\n    }\n  }\n  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CategoryBudgets on Budget {\n    categoryBudgets {\n      id\n      budgeted\n      category {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CategoryBudgets on Budget {\n    categoryBudgets {\n      id\n      budgeted\n      category {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Budget($month: DateTime!) {\n    budget(month: $month) {\n      id\n      month\n      ...CategoryBudgets\n    }\n  }\n  \n"): (typeof documents)["\n  query Budget($month: DateTime!) {\n    budget(month: $month) {\n      id\n      month\n      ...CategoryBudgets\n    }\n  }\n  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCategoryAggregation(\n    $startDate: DateTime!\n    $endDate: DateTime!\n    $interval: AggregationInterval!\n  ) {\n    categoryAggregation(startDate: $startDate, endDate: $endDate, interval: $interval) {\n      xLabels\n      startDate\n      endDate\n      aggregations {\n        category {\n          id\n          name\n          color\n        }\n        amounts\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCategoryAggregation(\n    $startDate: DateTime!\n    $endDate: DateTime!\n    $interval: AggregationInterval!\n  ) {\n    categoryAggregation(startDate: $startDate, endDate: $endDate, interval: $interval) {\n      xLabels\n      startDate\n      endDate\n      aggregations {\n        category {\n          id\n          name\n          color\n        }\n        amounts\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CategoryGroupFields on CategoryGroup {\n    id\n    name\n    categories {\n      id\n      name\n      color\n    }\n  }\n"): (typeof documents)["\n  fragment CategoryGroupFields on CategoryGroup {\n    id\n    name\n    categories {\n      id\n      name\n      color\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CategoryGroups {\n    categoryGroups {\n      ...CategoryGroupFields\n    }\n  }\n  \n"): (typeof documents)["\n  query CategoryGroups {\n    categoryGroups {\n      ...CategoryGroupFields\n    }\n  }\n  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CategoryGroupsWithSpend($startDate: DateTime!, $endDate: DateTime!) {\n    categoryGroups {\n      id\n      name\n      categories {\n        id\n        name\n        spend(startDate: $startDate, endDate: $endDate)\n        color\n      }\n    }\n  }\n"): (typeof documents)["\n  query CategoryGroupsWithSpend($startDate: DateTime!, $endDate: DateTime!) {\n    categoryGroups {\n      id\n      name\n      categories {\n        id\n        name\n        spend(startDate: $startDate, endDate: $endDate)\n        color\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCurrentUser {\n    currentUser {\n      id\n      name\n      email\n      isEmailVerified\n      currencyCode\n      stripeCustomer {\n        subscriptions {\n          id\n          status\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCurrentUser {\n    currentUser {\n      id\n      name\n      email\n      isEmailVerified\n      currencyCode\n      stripeCustomer {\n        subscriptions {\n          id\n          status\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetHoldings($accountId: String!) {\n    holdings(accountId: $accountId) {\n      id\n      security {\n        id\n        ticker\n        name\n      }\n      quantity\n      value\n      currencyCode\n    }\n  }\n"): (typeof documents)["\n  query GetHoldings($accountId: String!) {\n    holdings(accountId: $accountId) {\n      id\n      security {\n        id\n        ticker\n        name\n      }\n      quantity\n      value\n      currencyCode\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetIncomeVsExpense(\n    $startDate: DateTime!\n    $endDate: DateTime!\n    $interval: AggregationInterval!\n  ) {\n    incomeVsExpense(startDate: $startDate, endDate: $endDate, interval: $interval) {\n      xLabels\n      startDate\n      endDate\n      aggregations {\n        category {\n          id\n          name\n          color\n        }\n        amounts\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetIncomeVsExpense(\n    $startDate: DateTime!\n    $endDate: DateTime!\n    $interval: AggregationInterval!\n  ) {\n    incomeVsExpense(startDate: $startDate, endDate: $endDate, interval: $interval) {\n      xLabels\n      startDate\n      endDate\n      aggregations {\n        category {\n          id\n          name\n          color\n        }\n        amounts\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetItem($itemId: String!) {\n    item(itemId: $itemId) {\n      id\n      lastSyncedAt\n      lastInvestmentSyncedAt\n    }\n  }\n"): (typeof documents)["\n  query GetItem($itemId: String!) {\n    item(itemId: $itemId) {\n      id\n      lastSyncedAt\n      lastInvestmentSyncedAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetNetWorthHistory(\n    $startDate: DateTime!\n    $endDate: DateTime!\n    $interval: AssetAggregationInterval!\n  ) {\n    netWorthHistory(startDate: $startDate, endDate: $endDate, interval: $interval) {\n      netWorth\n      percentChange\n      history {\n        date\n        value\n      }\n      xLabels\n      xLabelFormat\n    }\n  }\n"): (typeof documents)["\n  query GetNetWorthHistory(\n    $startDate: DateTime!\n    $endDate: DateTime!\n    $interval: AssetAggregationInterval!\n  ) {\n    netWorthHistory(startDate: $startDate, endDate: $endDate, interval: $interval) {\n      netWorth\n      percentChange\n      history {\n        date\n        value\n      }\n      xLabels\n      xLabelFormat\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetOverviewElements {\n    overviewElements {\n      items {\n        id\n        lastSyncedAt\n        lastInvestmentSyncedAt\n        expiresAt\n        institutionName\n        externalPlatformType\n        elements {\n          id\n          nickname\n          type\n          balance\n          currencyCode\n          syncMode\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetOverviewElements {\n    overviewElements {\n      items {\n        id\n        lastSyncedAt\n        lastInvestmentSyncedAt\n        expiresAt\n        institutionName\n        externalPlatformType\n        elements {\n          id\n          nickname\n          type\n          balance\n          currencyCode\n          syncMode\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetPotentialTransfers($transactionId: String!) {\n    potentialTransfers(transactionId: $transactionId) {\n      id\n      date\n      payee\n      account {\n        nickname\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPotentialTransfers($transactionId: String!) {\n    potentialTransfers(transactionId: $transactionId) {\n      id\n      date\n      payee\n      account {\n        nickname\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetRecurringTransactions {\n    recurringTransactions {\n      id\n      amount\n      recurrenceInterval\n      active\n      payee\n      transactions {\n        id\n        date\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetRecurringTransactions {\n    recurringTransactions {\n      id\n      amount\n      recurrenceInterval\n      active\n      payee\n      transactions {\n        id\n        date\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetStripeProduct {\n    stripeProducts {\n      id\n      name\n      description\n      stripePrices {\n        id\n        amount\n        currency\n        recurrenceInterval\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetStripeProduct {\n    stripeProducts {\n      id\n      name\n      description\n      stripePrices {\n        id\n        amount\n        currency\n        recurrenceInterval\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetTransaction($transactionId: String!) {\n    transaction(transactionId: $transactionId) {\n      id\n      payee\n      date\n      amount\n      currencyCode\n      account {\n        id\n        nickname\n      }\n      linkedTransfer {\n        id\n        inflowTransaction {\n          id\n        }\n        outflowTransaction {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTransaction($transactionId: String!) {\n    transaction(transactionId: $transactionId) {\n      id\n      payee\n      date\n      amount\n      currencyCode\n      account {\n        id\n        nickname\n      }\n      linkedTransfer {\n        id\n        inflowTransaction {\n          id\n        }\n        outflowTransaction {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetTransactions(\n    $accountId: String\n    $cursor: String\n    $sort: TransactionSortOption\n    $filters: FilterOptions!\n  ) {\n    transactions(accountId: $accountId, cursor: $cursor, sort: $sort, filters: $filters) {\n      nextCursor\n      total\n      transactions {\n        id\n        payee\n        rawPayee\n        notes\n        date\n        amount\n        currencyCode\n        reviewed\n        hidden\n        settled\n        category {\n          id\n          name\n          color\n        }\n        merchant {\n          name\n          category\n        }\n        account {\n          id\n          nickname\n        }\n        linkedTransfer {\n          id\n          inflowTransaction {\n            id\n          }\n          outflowTransaction {\n            id\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTransactions(\n    $accountId: String\n    $cursor: String\n    $sort: TransactionSortOption\n    $filters: FilterOptions!\n  ) {\n    transactions(accountId: $accountId, cursor: $cursor, sort: $sort, filters: $filters) {\n      nextCursor\n      total\n      transactions {\n        id\n        payee\n        rawPayee\n        notes\n        date\n        amount\n        currencyCode\n        reviewed\n        hidden\n        settled\n        category {\n          id\n          name\n          color\n        }\n        merchant {\n          name\n          category\n        }\n        account {\n          id\n          nickname\n        }\n        linkedTransfer {\n          id\n          inflowTransaction {\n            id\n          }\n          outflowTransaction {\n            id\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ImportStatements($accountId: String!, $statements: [StatementFile!]!) {\n    importStatements(accountId: $accountId, statements: $statements) {\n      filename\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation ImportStatements($accountId: String!, $statements: [StatementFile!]!) {\n    importStatements(accountId: $accountId, statements: $statements) {\n      filename\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Login($email: String!, $password: String!) {\n    login(email: $email, password: $password) {\n      id\n      name\n      email\n      isEmailVerified\n    }\n  }\n"): (typeof documents)["\n  mutation Login($email: String!, $password: String!) {\n    login(email: $email, password: $password) {\n      id\n      name\n      email\n      isEmailVerified\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Logout {\n    logout {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation Logout {\n    logout {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation MergeAccounts($sourceAccountId: String!, $targetAccountId: String!) {\n  mergeAccounts(sourceAccountId: $sourceAccountId, targetAccountId: $targetAccountId) {\n    success\n  }\n}\n"): (typeof documents)["\nmutation MergeAccounts($sourceAccountId: String!, $targetAccountId: String!) {\n  mergeAccounts(sourceAccountId: $sourceAccountId, targetAccountId: $targetAccountId) {\n    success\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetNetWorthOverview {\n    netWorthOverview {\n      netWorth {\n        current\n        lastMonth\n      }\n      debts {\n        current\n        lastMonth\n      }\n      assets {\n        current\n        lastMonth\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetNetWorthOverview {\n    netWorthOverview {\n      netWorth {\n        current\n        lastMonth\n      }\n      debts {\n        current\n        lastMonth\n      }\n      assets {\n        current\n        lastMonth\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation RegisterSubscription($endpoint: String!, $p256dh: String!, $auth: String!) {\n        registerNotificationSubscription(endpoint: $endpoint, p256dh: $p256dh, auth: $auth) {\n          success\n        }\n      }\n    "): (typeof documents)["\n      mutation RegisterSubscription($endpoint: String!, $p256dh: String!, $auth: String!) {\n        registerNotificationSubscription(endpoint: $endpoint, p256dh: $p256dh, auth: $auth) {\n          success\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation ResetItemExpiresAt($itemId: String!) {\n      resetItemExpiresAt(itemId: $itemId) {\n        id\n        expiresAt\n      }\n    }\n  "): (typeof documents)["\n    mutation ResetItemExpiresAt($itemId: String!) {\n      resetItemExpiresAt(itemId: $itemId) {\n        id\n        expiresAt\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation SetBulkReviewed($bulkReviewInput: BulkReviewInput!) {\n      setBulkReviewed(bulkReviewInput: $bulkReviewInput) {\n        affectedTransactionIds\n      }\n    }\n  "): (typeof documents)["\n    mutation SetBulkReviewed($bulkReviewInput: BulkReviewInput!) {\n      setBulkReviewed(bulkReviewInput: $bulkReviewInput) {\n        affectedTransactionIds\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SetCategory($transactionId: String!, $categoryId: String) {\n    setCategory(transactionId: $transactionId, categoryId: $categoryId) {\n      id\n      category {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SetCategory($transactionId: String!, $categoryId: String) {\n    setCategory(transactionId: $transactionId, categoryId: $categoryId) {\n      id\n      category {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SetCategoryBudget($amount: Float!, $categoryId: String!, $budgetId: String!) {\n    setCategoryBudget(amount: $amount, categoryId: $categoryId, budgetId: $budgetId) {\n      id\n      category {\n        id\n      }\n      budget {\n        id\n      }\n      budgeted\n    }\n  }\n"): (typeof documents)["\n  mutation SetCategoryBudget($amount: Float!, $categoryId: String!, $budgetId: String!) {\n    setCategoryBudget(amount: $amount, categoryId: $categoryId, budgetId: $budgetId) {\n      id\n      category {\n        id\n      }\n      budget {\n        id\n      }\n      budgeted\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SetTransactionHidden($transactionId: String!, $hidden: Boolean!) {\n    setTransactionHidden(transactionId: $transactionId, hidden: $hidden) {\n      id\n      hidden\n    }\n  }\n"): (typeof documents)["\n  mutation SetTransactionHidden($transactionId: String!, $hidden: Boolean!) {\n    setTransactionHidden(transactionId: $transactionId, hidden: $hidden) {\n      id\n      hidden\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SetTransactionNotes($transactionId: String!, $notes: String!) {\n    setTransactionNotes(transactionId: $transactionId, notes: $notes) {\n      id\n      notes\n    }\n  }\n"): (typeof documents)["\n  mutation SetTransactionNotes($transactionId: String!, $notes: String!) {\n    setTransactionNotes(transactionId: $transactionId, notes: $notes) {\n      id\n      notes\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation SetTransactionPayee($transactionId: String!, $payee: String!) {\n      setTransactionPayee(transactionId: $transactionId, payee: $payee) {\n        affectedTransactionIds\n      }\n    }\n  "): (typeof documents)["\n    mutation SetTransactionPayee($transactionId: String!, $payee: String!) {\n      setTransactionPayee(transactionId: $transactionId, payee: $payee) {\n        affectedTransactionIds\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n          fragment UpdatePayee on Transaction {\n            id\n            payee\n          }\n        "): (typeof documents)["\n          fragment UpdatePayee on Transaction {\n            id\n            payee\n          }\n        "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SetTransactionReviewed($transactionId: String!, $reviewed: Boolean!) {\n    setTransactionReviewed(transactionId: $transactionId, reviewed: $reviewed) {\n      id\n      reviewed\n    }\n  }\n"): (typeof documents)["\n  mutation SetTransactionReviewed($transactionId: String!, $reviewed: Boolean!) {\n    setTransactionReviewed(transactionId: $transactionId, reviewed: $reviewed) {\n      id\n      reviewed\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SimulateAutomation($simulateAutomationInput: SimulateAutomationInput!) {\n    simulateAutomation(simulateAutomationInput: $simulateAutomationInput) {\n      count\n    }\n  }\n"): (typeof documents)["\n  query SimulateAutomation($simulateAutomationInput: SimulateAutomationInput!) {\n    simulateAutomation(simulateAutomationInput: $simulateAutomationInput) {\n      count\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SyncInvestmentHoldings($itemId: String!) {\n    syncInvestmentHoldings(itemId: $itemId) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation SyncInvestmentHoldings($itemId: String!) {\n    syncInvestmentHoldings(itemId: $itemId) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateAutomationSuggestion(\n      $updateAutomationSuggestionInput: UpdateAutomationSuggestionInput!\n    ) {\n      updateAutomationSuggestion(\n        updateAutomationSuggestionInput: $updateAutomationSuggestionInput\n      ) {\n        id\n        field\n        value\n        status\n        automation {\n          id\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateAutomationSuggestion(\n      $updateAutomationSuggestionInput: UpdateAutomationSuggestionInput!\n    ) {\n      updateAutomationSuggestion(\n        updateAutomationSuggestionInput: $updateAutomationSuggestionInput\n      ) {\n        id\n        field\n        value\n        status\n        automation {\n          id\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation VerifyEmail($code: String!) {\n    verifyEmail(code: $code) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation VerifyEmail($code: String!) {\n    verifyEmail(code: $code) {\n      success\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;