import {
  Box,
  Circle,
  Flex,
  Skeleton,
  useColorModeValue,
  useRadio,
  type UseRadioProps,
} from '@chakra-ui/react';

export const RadioCard = (
  props: { children: React.ReactChild | React.ReactChild[]; loading: boolean } & UseRadioProps,
) => {
  const {
    getInputProps,
    getRadioProps,
    state: { isChecked },
  } = useRadio(props);

  const input = getInputProps();
  const radio = getRadioProps();

  const activeColor = useColorModeValue('blue.600', 'blue.300');
  const inactiveColor = useColorModeValue('gray.300', 'gray.600');
  const activeBgColor = useColorModeValue('blue.50', 'blue.900');

  const borderColor = isChecked ? activeColor : inactiveColor;

  return (
    <Box as="label" flex={1} flexBasis={{ base: '100%', md: 1 }}>
      <Skeleton isLoaded={!props.loading}>
        <input {...input} />
        <Flex
          {...radio}
          cursor="pointer"
          borderWidth={3}
          borderRadius="lg"
          borderColor={borderColor}
          bg={isChecked ? activeBgColor : undefined}
          _focus={{
            boxShadow: 'outline',
          }}
          p={5}
          alignItems="center"
          gap={8}
          flexDir="column"
          justifyContent="space-between"
        >
          {props.children}
          <Flex
            borderWidth={3}
            borderColor={borderColor}
            borderRadius="full"
            width={6}
            height={6}
            justifyContent="center"
            alignItems="center"
          >
            <Circle size={3} bg={borderColor} hidden={!isChecked} />
          </Flex>
        </Flex>
      </Skeleton>
    </Box>
  );
};
