import { gql } from 'src/graphql/__generated__/gql';
import type { GetHoldingsQuery } from 'src/graphql/__generated__/graphql';

export const GET_HOLDINGS = gql(/* GraphQL */ `
  query GetHoldings($accountId: String!) {
    holdings(accountId: $accountId) {
      id
      security {
        id
        ticker
        name
      }
      quantity
      value
      currencyCode
    }
  }
`);

export const GET_HOLDINGS_PLACEHOLDER: GetHoldingsQuery = {
  holdings: [
    {
      id: crypto.randomUUID(),
      security: {
        id: crypto.randomUUID(),
        ticker: 'AAPL',
        name: 'Apple Inc.',
        __typename: 'Security',
      },
      quantity: 10,
      value: 1750_00n,
      currencyCode: 'USD',
      __typename: 'InvestmentHolding',
    },
    {
      id: crypto.randomUUID(),
      security: {
        id: crypto.randomUUID(),
        ticker: 'MSFT',
        name: 'Microsoft Corporation',
        __typename: 'Security',
      },
      quantity: 5,
      value: 1900_00n,
      currencyCode: 'USD',
      __typename: 'InvestmentHolding',
    },
    {
      id: crypto.randomUUID(),
      security: {
        id: crypto.randomUUID(),
        ticker: 'GOOGL',
        name: 'Alphabet Inc.',
        __typename: 'Security',
      },
      quantity: 2,
      value: 2800_00n,
      currencyCode: 'USD',
      __typename: 'InvestmentHolding',
    },
  ],
};
