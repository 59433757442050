import {
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  Tabs,
  TabPanel,
  TabList,
  TabPanels,
  type useDisclosure,
  Flex,
  Button,
  useToast,
  Icon,
} from '@chakra-ui/react';
import { type Dispatch, type SetStateAction, useState } from 'react';
import { utc } from 'src/util/dayjs';
import { ReconnectPlaidButton } from 'src/components/account/ReconnectPlaidButton';
import type { Item } from './AccountSection';
import { ExternalPlatform } from 'src/graphql/__generated__/graphql';
import { useMutation } from '@apollo/client';
import { DELETE_PLAID_ITEM } from 'src/graphql/DeletePlaidItem';
import { useTranslation } from 'react-i18next';
import { cache } from 'src/cache';
import { PiLinkBreakBold } from 'react-icons/pi';

export const ReauthorizePanel = ({
  item,
  onClose,
  setPlaidLinkOpen,
}: {
  item: Item;
  onClose: EditItemModalProps['onClose'];
  setPlaidLinkOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const REAUTHORIZE_TEXT =
    'Our connection with your account has expired. This can happen when your password changes, when MFA requirements change, or when your login becomes locked.';
  const DEFAULT_TEXT =
    'If you want to add or remove accounts, you can update your connection below.';
  const descriptionText = utc(item.expiresAt).isSameOrBefore(utc())
    ? REAUTHORIZE_TEXT
    : DEFAULT_TEXT;

  return (
    <Flex flexDir={'column'} gap={6}>
      <Text>{descriptionText}</Text>
      <ReconnectPlaidButton
        onClose={onClose}
        itemId={item.id}
        setPlaidLinkOpen={setPlaidLinkOpen}
      />
    </Flex>
  );
};

const SettingsPanel = ({
  item,
  onClose,
}: {
  item: Item;
  onClose: EditItemModalProps['onClose'];
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [deletePlaidItem, { loading }] = useMutation(DELETE_PLAID_ITEM, {
    variables: { itemId: item.id },
    onCompleted: () => {
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'overviewElements' });
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'transactions' });
      toast({
        title: t('items.editModal.connectionRemovedSuccessfully', {
          institutionName: item.institutionName,
        }),
        status: 'success',
        duration: 5000,
      });
      onClose();
    },
    onError: (error) => {
      toast({
        title: t('items.editModal.errorRemovingConnection'),
        description: error.message,
        status: 'error',
        duration: 5000,
      });
    },
  });

  return (
    <Flex flexDir="column" gap={6}>
      <Text>{t('items.editModal.removeConnectionDescription')}</Text>
      <Button
        colorScheme="red"
        isLoading={loading}
        onClick={() => deletePlaidItem()}
        leftIcon={<Icon as={PiLinkBreakBold} w={5} h={5} />}
      >
        {t('items.editModal.removeConnection')}
      </Button>
    </Flex>
  );
};

interface EditItemModalProps {
  isOpen: boolean;
  onClose: ReturnType<typeof useDisclosure>['onClose'];
  item: Item;
}

export const EditItemModal = ({ isOpen, onClose, item }: EditItemModalProps) => {
  const [plaidLinkOpen, setPlaidLinkOpen] = useState<boolean>(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" trapFocus={false}>
      <ModalOverlay hidden={plaidLinkOpen} />
      <ModalContent hidden={plaidLinkOpen}>
        <ModalHeader>
          <Text>{item.institutionName}</Text>
        </ModalHeader>
        <ModalBody>
          <Tabs variant="enclosed">
            <TabList>
              <Tab hidden={item === null || item.externalPlatformType !== ExternalPlatform.Plaid}>
                Reauthorize
              </Tab>
              <Tab>Settings</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ReauthorizePanel
                  item={item}
                  onClose={onClose}
                  setPlaidLinkOpen={setPlaidLinkOpen}
                />
              </TabPanel>
              <TabPanel>
                <SettingsPanel item={item} onClose={onClose} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};
